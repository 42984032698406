<template>
  <div class="add-Interface">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <el-tabs class="demo-tabs">
        <el-tab-pane label="添加频道">
          <el-form label-width="120px">
            <el-form-item label="频道名称:">
              <el-input v-model="ruleForm2.name" placeholder="频道名称" />
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleSaveClick2" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="添加页面">
          <el-form
            ref="ruleFormRef3"
            :model="ruleForm3"
            :rules="rules3"
            label-width="120px"
          >
            <el-form-item prop="cid" label="频道:">
              <el-select
                v-model="ruleForm3.cid"
                class="m-2"
                placeholder="选择频道"
                size="large"
              >
                <el-option
                  v-for="item in pindaoList"
                  :key="item.id"
                  :label="item.channelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="name" label="页面名称:">
              <el-input v-model="ruleForm3.name" placeholder="页面名称" />
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleSaveClick3(ruleFormRef3)" type="primary"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="添加功能">
          <el-form
            ref="ruleFormRef4"
            :model="ruleForm4"
            :rules="rules2"
            label-width="120px"
          >
            <el-form-item prop="cid" label="频道:">
              <el-select
                @change="handlePingdaoChange"
                v-model="ruleForm4.cid"
                class="m-2"
                placeholder="选择频道"
                size="large"
              >
                <el-option
                  v-for="item in pindaoList"
                  :key="item.id"
                  :label="item.channelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="id" label="页面:">
              <el-select
                v-model="ruleForm4.id"
                class="m-2"
                placeholder="选择页面"
                size="large"
              >
                <el-option
                  v-for="item in pageList"
                  :key="item.id"
                  :label="item.pageName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="name" label="功能名称:">
              <el-input v-model="ruleForm4.name" placeholder="功能名称" />
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleSaveClick4(ruleFormRef4)" type="primary"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="添加接口">
          <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
            <el-form-item prop="cid" label="频道:">
              <el-select
                @change="handlePingdaoChange"
                v-model="ruleForm.cid"
                class="m-2"
                placeholder="选择频道"
                size="large"
              >
                <el-option
                  v-for="item in pindaoList"
                  :key="item.id"
                  :label="item.channelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="pid" label="页面:">
              <el-select
                v-model="ruleForm.pid"
                class="m-2"
                placeholder="选择页面"
                size="large"
                @change="handlePageChange"
              >
                <el-option
                  v-for="item in pageList"
                  :key="item.id"
                  :label="item.pageName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item prop="funcid" label="功能:">
              <el-select
                v-model="ruleForm.funcid"
                class="m-2"
                placeholder="选择功能"
                size="large"
              >
                <el-option
                  v-for="item in gongnengList"
                  :key="item.id"
                  :label="item.funcname"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item prop="name" label="接口名称:">
              <el-input v-model="ruleForm.name" placeholder="接口名称" />
            </el-form-item>
            <el-form-item prop="url" label="接口地址:">
              <el-input v-model="ruleForm.url" placeholder="接口地址" />
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleSaveClick(ruleFormRef)" type="primary"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { breadcrumbList, rules, rules2, rules3 } from './config'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { getPingdaoList, getPingdaoPageList, addInter, addChannel, addPage, addGongNeng, getGongNengList } from '@/service/main/base'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb
  },
  setup() {
    const ruleForm = reactive({
      cid: "",
      pid: "",
      name: "",
      url: "",
      funcid: ""
    })

    const ruleForm2 = reactive({
      name: ""
    })

    const ruleForm3 = reactive({
      cid: "",
      name: "",
    })

    const ruleForm4 = reactive({
      cid: "",
      id: '',
      name: "",
    })

    const pindaoList = ref([])
    const pageList = ref([])
    const initPage = async () => {
      const res = await getPingdaoList()
      let list = res.data.channels
      pindaoList.value = list
      handlePingdaoChange(list[0].id)
    }
    initPage()

    const handlePingdaoChange = async value => {
      const res = await getPingdaoPageList(value)
      pageList.value = res.data.pages
    }

    const ruleFormRef = ref()
    const ruleFormRef3 = ref()
    const ruleFormRef4 = ref()

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await addInter({ ...ruleForm })
          if (res.code == 0) {
            ElMessage({
              message: '添加成功!',
              type: 'success',
            })
            ruleForm.pid = ''
            ruleForm.name = ''
            ruleForm.url = ''
            ruleForm.funcid = ''
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const handleSaveClick2 = async () => {
      const res = await addChannel(ruleForm2.name)
      if (res.code == 0) {
        ElMessage({
          message: '添加成功!',
          type: 'success',
        })
        initPage()
        ruleForm2.name = ''
      }
    }

    const handleSaveClick3 = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await addPage(ruleForm3.cid, ruleForm3.name)
          if (res.code == 0) {
            ElMessage({
              message: '添加成功!',
              type: 'success',
            })
            initPage()
            ruleForm3.name = ''
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const handleSaveClick4 = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let { id, name } = ruleForm4
          const res = await addGongNeng({
            id, name
          })
          if (res.code == 0) {
            ElMessage({
              message: '添加成功!',
              type: 'success',
            })
            const res = await getPingdaoList()
            let list = res.data.channels
            pindaoList.value = list
            handlePingdaoChange(ruleForm4.cid)
            ruleForm4.id = ''
            ruleForm4.name = ''
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }
    const gongnengList = ref([])
    const handlePageChange = async value => {
      ruleForm.funcid = ''
      const res = await getGongNengList(value)
      gongnengList.value = res.data.list

    }

    return {
      gongnengList,
      handlePageChange,
      handleSaveClick4,
      ruleFormRef4,
      ruleFormRef3,
      handleSaveClick3,
      ruleForm3,
      ruleForm4,
      handleSaveClick2,
      ruleForm2,
      handleSaveClick,
      ruleFormRef,
      handlePingdaoChange,
      breadcrumbList,
      ruleForm,
      pageList,
      pindaoList,
      rules,
      rules3,
      rules2,
    }

  }
})
</script>

<style scoped lang="less">
.add-Interface {
  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

    &:deep(.el-input) {
      max-width: 400px;
    }
  }
}
</style>
