export const breadcrumbList = [
  {
    name: '权限',
    path: ''
  },
  {
    name: '添加权限',
    path: ''
  }
]

export const rules={
  cid:[ { required: true, message: '选择频道', trigger: 'blur' },],
  pid:[ { required: true, message: '选择页面', trigger: 'blur' },],
  name:[ { required: true, message: '输入接口名称', trigger: 'blur' },],
  url:[ { required: true, message: '输入接口地址', trigger: 'blur' },],
  funcid:[ { required: true, message: '选择功能', trigger: 'blur' },],
}

export const rules2={
  cid:[ { required: true, message: '选择频道', trigger: 'blur' },],
  id:[ { required: true, message: '选择页面', trigger: 'blur' },],
  name:[ { required: true, message: '输入接口名称', trigger: 'blur' },],
}



export const rules3={
  cid:[ { required: true, message: '选择频道', trigger: 'blur' },],
  name:[ { required: true, message: '输入页面名称', trigger: 'blur' },],
}
